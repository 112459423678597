import { axiosCreate } from '@/services/Api'
import store from '@/state/store'

export default async function getVoucherReservations() {
  const result = await axiosCreate.get(`shop/reservationOrders/cust/voucher/`, {
    headers: { authorization: store.getters.token }
  })

  return result.data
}
